import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import GetPayoutRequestsService from '../../services/get/payouts/getPayouts';
import { getCommissionsByMonth } from '../../services/get/commissions/getCommissionsByMonth';
import {
  PayoutMonthDTO,
  CommissionDetail,
  Page,
} from '../../services/get/payouts/models';
import { green, yellow } from '../../../../themes/index';
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import classes from './payoutCard.module.scss';
import { useTranslation } from 'react-i18next';
import PayoutCardSkeleton from './PayoutCardSkeleton';

const PayoutCard = () => {
  const theme = useTheme();
  const { t } = useTranslation('partnerArea');
  const { tokens } = useAuth();
  const { isXS } = useBreakpoints();

  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState<{ [key: string]: boolean }>({});
  const [payoutMonths, setPayoutMonths] = useState<PayoutMonthDTO[]>([]);
  const [currentPage, setCurrentPage] = useState<{ [key: string]: number }>({});
  const [currentDetails, setCurrentDetails] = useState<{
    [key: string]: CommissionDetail[];
  }>({});
  const [totalPages, setTotalPages] = useState<{ [key: string]: number }>({});

  const pageSize = 10;

  // Fonction pour déterminer la couleur du Chip en fonction du status
  const getChipColor = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return green[300];
      case 'PHASE_1':
      case 'PHASE_2':
        return yellow[800];
      default:
        return theme.palette.primary.main;
    }
  };

  // Chargement des données initiales depuis /v2/affiliations/payout-requests
  useEffect(() => {
    setLoading(true);
    const fetchPayoutRequests = async () => {
      if (tokens) {
        try {
          const response = await GetPayoutRequestsService.getPayoutRequests(
            tokens.accessToken,
          );
          setPayoutMonths(response.payoutMonths);
        } catch (error) {
          setLoading(false);
          console.error(
            'Erreur lors de la récupération des données de payout',
            error,
          );
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPayoutRequests();
  }, [tokens]);

  // Chargement des données pour chaque page de commissions
  const loadCommissionDetails = async (
    month: string,
    monthValue: number,
    year: number,
    pageNumber: number,
  ) => {
    if (!tokens) return;
    setPageLoading(true);

    try {
      const response: Page<CommissionDetail> = await getCommissionsByMonth(
        tokens.accessToken,
        monthValue,
        year,
        pageNumber,
        pageSize,
      );

      const details = response.content || []; // Prendre le contenu de la page
      setCurrentDetails((prevState) => ({
        ...prevState,
        [month]: details,
      }));

      setTotalPages((prevState) => ({
        ...prevState,
        [month]: response.totalPages || 1, // Nombre total de pages
      }));
    } catch (error) {
      setPageLoading(false);
      console.error(
        'Erreur lors du chargement des détails de commission',
        error,
      );
    } finally {
      setPageLoading(false);
    }
  };

  const handleCardClick = (month: string) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [month]: !prevState[month],
    }));

    if (!currentPage[month]) {
      setCurrentPage((prevState) => ({ ...prevState, [month]: 1 }));
    }

    if (!currentDetails[month]) {
      const payoutMonth = payoutMonths.find((p) => p.month === month);
      if (payoutMonth) {
        setCurrentDetails((prevState) => ({
          ...prevState,
          [month]: payoutMonth.commissionDetails.content,
        }));
        setTotalPages((prevState) => ({
          ...prevState,
          [month]: payoutMonth.commissionDetails.totalPages,
        }));
      }
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
    month: string,
    monthValue: number,
    year: number,
  ) => {
    event.stopPropagation();
    setCurrentPage((prevState) => ({
      ...prevState,
      [month]: page,
    }));

    loadCommissionDetails(month, monthValue, year, page - 1); // Appeler pour charger les données de la page spécifique
  };

  if (loading) {
    return <PayoutCardSkeleton />;
  }

  return (
    <>
      {payoutMonths.map((payoutMonth) => {
        const totalPagesForMonth = totalPages[payoutMonth.month] || 1;
        return (
          <Card
            key={payoutMonth.monthValue}
            elevation={0}
            onClick={() => handleCardClick(payoutMonth.month)}
            className={classes.card}
            sx={{
              border: isExpanded[payoutMonth.month]
                ? '2px solid rgb(209, 72, 72)'
                : '2px solid transparent',
              transition: 'border 0.3s',
            }}
          >
            <CardContent sx={{ '&:last-child': { paddingBottom: '10px' } }}>
              <Stack
                direction={isXS ? 'column' : 'row'}
                spacing={2}
                justifyContent={'space-between'}
                style={{ width: '100%' }}
              >
                <Typography variant="h5" component={'h5'}>
                  {payoutMonth.month} {payoutMonth.year}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    color: payoutMonth.alreadyRequested
                      ? payoutMonth.alreadyProcessed
                        ? green[300]
                        : yellow[800]
                      : theme.palette.primary.main,
                    borderColor: payoutMonth.alreadyRequested
                      ? payoutMonth.alreadyProcessed
                        ? green[300]
                        : yellow[800]
                      : theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      borderColor: payoutMonth.alreadyRequested
                        ? payoutMonth.alreadyProcessed
                          ? green[300]
                          : yellow[800]
                        : theme.palette.primary.main,
                    },
                  }}
                >
                  {payoutMonth.amount.toFixed(2)} € |{' '}
                  {payoutMonth.alreadyRequested
                    ? payoutMonth.alreadyProcessed
                      ? t('PAYOUT_CARD.STATUS_PROCESSED')
                      : t('PAYOUT_CARD.STATUS_IN_REVIEW')
                    : t('PAYOUT_CARD.STATUS_NOT_AVAILABLE')}
                </Button>
              </Stack>
            </CardContent>
            <Collapse
              in={isExpanded[payoutMonth.month]}
              timeout="auto"
              unmountOnExit
            >
              <Divider variant="middle" sx={{ marginTop: '16px' }} />
              <CardContent sx={{ marginTop: '10px' }}>
                {pageLoading ? (
                  <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={300}
                    width={'100%'}
                  >
                    <CircularProgress />
                  </Stack>
                ) : (
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{ marginBottom: 6 }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_ID')}
                          </TableCell>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_NAME')}
                          </TableCell>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_PRICE_PAID')}
                          </TableCell>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_COMMISSION_EARNED')}
                          </TableCell>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_PHASE')}
                          </TableCell>
                          <TableCell align="left">
                            {t('PAYOUT_CARD.TABLE_HEAD_TRANSACTION_DATE')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentDetails[payoutMonth.month]?.map(
                          (commission) => (
                            <TableRow key={commission.id}>
                              <TableCell align="left">
                                {commission.id}
                              </TableCell>
                              <TableCell align="left">
                                {commission.name}
                              </TableCell>
                              <TableCell align="left">
                                {commission.pricePaid}
                              </TableCell>
                              <TableCell align="left">
                                {commission.commissionEarned}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={commission.status.replace('_', ' ')}
                                  sx={{
                                    backgroundColor: getChipColor(
                                      commission.status,
                                    ),
                                    color: theme.palette.getContrastText(
                                      getChipColor(commission.status),
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">
                                {new Date(
                                  commission.transactionDate,
                                ).toLocaleDateString()}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {totalPagesForMonth > 1 && (
                  <Pagination
                    count={totalPagesForMonth}
                    page={currentPage[payoutMonth.month] || 1}
                    variant="outlined"
                    shape="rounded"
                    disabled={pageLoading}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event, page) =>
                      handlePageChange(
                        event,
                        page,
                        payoutMonth.month,
                        payoutMonth.monthValue,
                        Number(payoutMonth.year),
                      )
                    }
                  />
                )}
              </CardContent>
            </Collapse>
          </Card>
        );
      })}
    </>
  );
};

export default PayoutCard;
