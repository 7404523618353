import React, { useEffect, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

import classes from './_paymentWidget.module.scss';
import { decodeJWT, JWTPayload } from '../../pages/user/profil/services/auth/decodeJWT'
declare global {
  interface Window {
    widget :any;
  }
}


const PaymentWidget: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { getCode } = require('country-list');


const getCustomerUUID = (): string => {
  const storedAccessToken =
    localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');

  if (storedAccessToken) {
    const decoded: JWTPayload | null = decodeJWT(storedAccessToken);
    if (decoded?.userId) {
      return decoded.userId; // Utilisez le champ `userId` comme UUID
    }
    console.warn('Aucun userId trouvé dans le JWT. Génération d’un UUID.');
  } else {
    console.warn('Aucun token trouvé. Génération d’un UUID.');
  }
  return uuidv4(); // Génération d’un UUID si aucune donnée valide n'est trouvée
};

const customerUUID = getCustomerUUID();

  useEffect(() => {
    // Désactiver le scroll de la page
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const paymentInfo = localStorage.getItem('paymentInfo');
    if (!paymentInfo) {
      handleError('Aucune information de paiement trouvée.');
      return;
    }

    let parsedInfo;
    try {
      parsedInfo = JSON.parse(paymentInfo);
    } catch (e) {
      handleError('Erreur de lecture des informations de paiement.');
      return;
    }

    const { finalAmount, externalId, email, firstname, lastname, phoneNumber, street, city, zipCode, country, birthDate } = parsedInfo || {};
    if (!finalAmount || !externalId || !email || !firstname || !lastname) {
      handleError('Informations de paiement incomplètes.');
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@paycore/merchant-widget-js@0.4.1/dist/merchantWidget.umd.min.js';
    script.async = true;
    const handleWidgetEvents = (event: MessageEvent) => {    
    const data = event.data;
    
      // Vérifiez si `data` est une chaîne de caractères
      if (typeof data === 'string') {
    
        switch (data) {
          case 'rendered':
            setLoading(false);
            break;
    
          case 'status_page':
            window.location.href = `https://${process.env.REACT_APP_API_URL}/buy-challenge/payment-status/${externalId}`;
            break;
    
          default:
            console.warn('Type de message inconnu :', data);
        }
        return;
      }
    
      // Si `data` n'est pas une chaîne, traitez-le comme un objet
      if (typeof data === 'object' && data !== null) {    
        switch (data.type) {
          case 'rendered':
            setLoading(false);
            break;
    
          case 'status_page':
            window.location.href = `https://${process.env.REACT_APP_API_URL}/buy-challenge/payment-status/${externalId}`;
            break;
    
          default:
            console.warn('Type de message inconnu :', data.type, data);
        }
        return;
      }
    
      // Cas non pris en charge
      console.warn('Événement inattendu ou mal structuré :', data);
    };
    

    script.onload = () => {
        try {
          window.widget.init({
              public_key: 'pk_live_Ke0q6neYt0FF4RKfqa451WyNmbu5WcckdooMrTBxVAo',
              baseUrl: "https://com.paycore.io/hpp/",
              flow: 'iframe',
              selector: 'merchant-widget-mount-point',
              amount: finalAmount,
              currency: 'EUR',
              service: 'payment_card_eur_hpp',
              language: 'en',
              reference_id: externalId,
              description: '',
              customer: {
                reference_id: customerUUID,
                email: email,
                name: firstname + " " + lastname,
                phone: phoneNumber,
                date_of_birth : birthDate,
                address : {
                  full_address: `${street}, ${city}, ${zipCode}, ${country}`,
                  country : getCode(country),
                  city: city,
                  street: street,
                  post_code : zipCode
                  
                }
              },
              style: { theme: 'dark' },
              display: {
                hide_header: true,
                hide_footer: true,
                hide_progress_bar: true,
                hide_method_filter: true,
                hide_lifetime_counter: true,
              }});
          window.addEventListener('message', handleWidgetEvents);
        } catch (err) {
          handleError('Erreur lors de l’initialisation du widget.');
          console.error('Erreur d’initialisation:', err);
        }
      
    };

    script.onerror = () => handleError('Erreur lors du chargement du script.');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.removeEventListener('message', handleWidgetEvents);
    };
  }, [onClose]);

  const handleError = (message: string) => {
    setError(message);
    console.error(message);
    // Supprimer `onClose()` ici pour laisser l'utilisateur voir l'erreur
  };

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContent}>
        {loading && !error && (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        )}
        {error && (
          <div className={classes.errorMessage}>
            <p>{error}</p>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            backgroundColor: '#f5f5f5',
            color: '#1A1D21',
            width: 24,
            height: 24,
            borderRadius: 1,
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
            padding: 2,
            zIndex: 2100,
            transition: 'transform 0.2s ease, background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#eaeaea',
              transform: 'scale(1.05)',
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <div id="merchant-widget-mount-point" style={{ width: '400px', height: '600px', position: 'relative' ,borderRadius:'10px !important' }}></div>
      </div>
    </div>
  );
};

export default PaymentWidget;
