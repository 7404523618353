import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const version = '1.0.11';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // Activer ou désactiver les logs de débogage
    fallbackLng: 'en', // Si la langue n'est pas supportée, utiliser 'en'
    returnEmptyString: false,
    nsSeparator: ':',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    },
    ns: [
      'common',
      'sidebar',
      'login',
      'forms',
      'clientArea',
      'components',
      'area',
    ],
    defaultNS: 'common',
    detection: {
      // Configurer la détection de langue
      order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'], // Cache la langue choisie dans localStorage
    },
    interpolation: {
      escapeValue: false, // React s'occupe déjà de l'échappement
    },
    supportedLngs: ['en', 'fr', 'es', 'ar'], // Liste des langues supportées
  });

export default i18n;
