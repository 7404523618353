import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Stepper from '../../components/Stepper/Stepper';
import classes from './_paymentStatusPage.module.scss';
import Button from '../../components/userInterface/Button/Button';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import {
  retryPayment,
  retryPaymentAPS,
  retryPaymentVasu,
} from '../../services/shop/shop';
import OldModal from '../../components/OldModal/OldModal';

// <-- On importe notre composant Pixel avec la modif pour accepter un prop "purchaseValue"
import PixelBuySuccess from '../../utils/pixel/PixelBuySuccess';

import { Button as MuiButton } from '@mui/material';
import { getSelectedChallenges } from '../../features/area/services/get/challenges/getChallenges';
import { setChallengeType } from '../../features/area/services/post/challenges/postChallenges';
import {
  ChallengeCommissionType,
  ChallengeToSelect,
  ChallengeTypeSelection
} from '../../features/area/services/get/challenges/models';

import ECN_DARK_BUTTON from '../../assets/images/paymentStatus/ECN_Dark.png'
import STP_DARK_BUTTON from '../../assets/images/paymentStatus/STP_Dark.png'
import ECN_LIGHT_BUTTON from '../../assets/images/paymentStatus/ECN_Light.png'
import STP_LIGHT_BUTTON from '../../assets/images/paymentStatus/STP_Light.png'
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';

type PaymentStatusType =
  | 'AWAITING_PAYMENT'
  | 'AWAITING_MT5_ACCOUNT_CREATION'
  | 'ACCOUNT_CREATED_SUCCESSFULLY'
  | 'MT5_ACCOUNT_CREATION_FAILED'
  | 'SOLVED_BY_ADMIN'
  | 'FAILED'
  | 'FAILED_APS_1'
  | 'FAILED_APS_2'
  | 'CANCELLED'
  | 'UNKNOWN_STATUS'
  | 'PAYMENT_PENDING';

const PaymentStatusPage: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useThemeContext();

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusType>('PAYMENT_PENDING');
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [uuid, setUuid] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [ApsFailed, setApsFailed] = useState(false);
  const [Aps1Failed, setAps1Failed] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [selectedChallenges, setSelectedChallenges] = useState<ChallengeToSelect[]>([]);
  const [currentChallenge, setCurrentChallenge] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [currentOfferChoice, setCurrentOfferChoice] = useState<ChallengeCommissionType>('STP');
  const [isTrigger, setIsTrigger] = useState<boolean>(false);

  // AJOUT : pour stocker la valeur d’achat récupérée
  const [purchaseValue, setPurchaseValue] = useState<number | null>(null);

  const { t } = useTranslation(['paymentStatusPage']);
  const { paymentId } = useParams<{ paymentId: string }>();

  const steps = [
    {
      title: 'Processing Payment',
      text: 'Your payment is currently being processed. This should only take a moment. Thank you for your patience.',
      customContent: <Loader />,
    },
    {
      title: 'Creating Account',
      text: 'We are now creating your account.',
      customContent: <Loader />,
    },
    {
      title: 'Account Created',
      text: 'Your account has been successfully created. Thank you for using our platform! Check your email for login details for the dashboard and MetaTrader. 🎉',
    },
  ];

  // Pour mapper paymentStatus -> index du step
  const paymentStatusToStepIndex: Record<PaymentStatusType, number> = {
    AWAITING_PAYMENT: 0,
    AWAITING_MT5_ACCOUNT_CREATION: 1,
    ACCOUNT_CREATED_SUCCESSFULLY: 2,
    MT5_ACCOUNT_CREATION_FAILED: -1,
    SOLVED_BY_ADMIN: -1,
    FAILED: -1,
    FAILED_APS_1: -1,
    FAILED_APS_2: -1,
    CANCELLED: -1,
    UNKNOWN_STATUS: -1,
    PAYMENT_PENDING: 0,
  };

  // Mémoriser la redirection si query param `reference_id` dans l'URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referenceId = params.get('reference_id');

    if (referenceId) {
      const newUrl = `/buy-challenge/payment-status/${referenceId}`;
      navigate(newUrl, { replace: true });
    }
  }, [navigate]);

  // Mise à jour du step (de 0,1,2...)
  useEffect(() => {
    setCurrentStep(paymentStatusToStepIndex[paymentStatus]);
  }, [paymentStatus]);

  // Récupération de l'UUID dans l'URL
  useEffect(() => {
    const currentUrl = window.location.href;
    const pattern = /\/buy-challenge\/payment-status\/([a-f0-9-]{36})/;
    const match = currentUrl.match(pattern);

    if (match) {
      setUuid(match[1]);
    }
  }, []);

  // Récupération du statut de paiement (polling toutes les 8s)
  const fetchPaymentStatus = async () => {
    if (
      paymentId &&
      (currentStep === 0 || currentStep === 1) &&
      document.visibilityState === 'visible'
    ) {
      try {
        const response = await axios.get(
          `https://${process.env.REACT_APP_API_URL}/api/shop/status/${paymentId}`
        );
        const status = response.data as PaymentStatusType;
        setPaymentStatus(status);

        // On déclenche la logique de challenge si on passe à l'étape 1
        if (!isTrigger && paymentStatusToStepIndex[status] === 1) {
          handleSelectedChallenges(paymentId);
          setIsTrigger(true);
        }

        if (response.data === 'FAILED_APS_PAYMENT') {
          setApsFailed(true);
        }
        if (response.data === 'FAILED_APS_1') {
          setAps1Failed(true);
        }
      } catch (error) {
        setCurrentStep(-1); // On met le step à -1 pour signifier une erreur
      }
    }
  };

  useEffect(() => {
    fetchPaymentStatus();
    const intervalId = setInterval(fetchPaymentStatus, 8000);

    return () => {
      clearInterval(intervalId);
    };
  }, [paymentId, currentStep]);

  // On surveille quand on passe au step 2 pour aller chercher la valeur d’achat
  // => correspond à "ACCOUNT_CREATED_SUCCESSFULLY"
  useEffect(() => {
    if (currentStep === 2 && paymentId) {
      // Appel à l’endpoint : https://live.raisemyfunds.co/api/shop/amount/{payment_id}
      axios
        .get(`https://live.raisemyfunds.co/api/shop/amount/${paymentId}`)
        .then((response) => {
          // On suppose que le retour est un number (ex: 249)
          const value = response.data;
          setPurchaseValue(value);
        })
        .catch((err) => {
          console.error('Erreur pendant la récupération du montant : ', err);
        });
    }
  }, [currentStep, paymentId]);

  // Fonctions de retry
  const handleRetryPayment = async () => {
    if (uuid) {
      setLoading(true);
      try {
        const url = await retryPayment(uuid);
        window.location.href = url;
      } catch (error) {
        console.error('Erreur lors de handleRetryPayment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRetryPaymentAPS = async () => {
    if (uuid) {
      setLoading(true);
      try {
        const url = await retryPaymentAPS(uuid);
        window.location.href = url;
      } catch (error) {
        console.error('Erreur lors de handleRetryPayment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    setSelectedDate(dateValue);
    if (dateValue) {
      setError('');
      formatDate(dateValue);
    }
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    setFormattedDate(`${year}-${month}-${day}`);
  };

  const handleRetryPaymentVasu = async () => {
    if (!selectedDate) {
      setError('Please select a date of birth.');
    } else {
      if (uuid) {
        setLoading(true);
        try {
          const url = await retryPaymentVasu(uuid, selectedDate);
          window.location.href = url;
        } catch (error) {
          console.error('Erreur lors de handleRetryPaymentVasu:', error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleSelectedChallenges = async (externalPaymentId: string) => {
    try {
      const selectedChallenges = await getSelectedChallenges(externalPaymentId);
      if (selectedChallenges) {
        setSelectedChallenges(selectedChallenges);
      } else {
        setError('selectedChallenges is null or empty');
      }
    } catch (error) {
      console.error('Error fetching selected challenges:', error);
    }
  };

  const handleSetChallengeType = async (selectedChallenge: ChallengeTypeSelection) => {
    try {
      await setChallengeType(selectedChallenge);
      setCurrentChallenge((prev) => {
        const newChallengeIndex = prev + 1;
        if (newChallengeIndex < selectedChallenges.length) {
          return newChallengeIndex;
        }
        return prev; // Retourne l'indice actuel si le nouvel indice est hors des limites
      });

      if (currentChallenge + 1 === selectedChallenges.length) {
        setDisableButton(true);
        setTimeout(async () => {
          await fetchPaymentStatus();
        }, 500);
      }
    } catch (error) {
      console.error('Error during set type of challenge:', error);
    }
  };

  const closeWithdrawModal = () => {
    // Logic to close the modal
  };

  const handleStepChange = (newStep: number) => {
    // Logic to handle step change
  };

  return (
    <>
      {/* 
        On n'affiche PixelBuySuccess qu'une fois qu'on a la valeur d'achat 
        et qu'on est au step 2 (= compte créé). 
        Ainsi, on passe purchaseValue en prop.
      */}
      {currentStep === 2 && purchaseValue !== null && (
        <PixelBuySuccess purchaseValue={purchaseValue} />
      )}

      <OldModal
        title="Payment Process"
        isOpen={true}
        onClose={closeWithdrawModal}
        showCloseButton={false}
        fullOpacity={true}
      >
        <Stepper
          steps={steps}
          currentStep={currentStep}
          onStepChange={handleStepChange}
          className={classes.stepper}
          showNextButton={false}
        >
          <div className={classes.stepContent}>
            {currentStep === -1 ? (
              <>
                <p>An error occurred</p>
                {error && (
                  <div style={{ color: 'red', fontSize: '14px' }}>{error}</div>
                )}
                {Aps1Failed ? (
                  <>
                    <Button
                      variant="primary"
                      onClick={() => navigate('/buy-challenge')}
                      disabled={loading}
                    >
                      {loading ? 'Processing...' : 'Return to our shop'}
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => navigate('/buy-challenge')}
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Return to our shop'}
                  </Button>
                )}
              </>
            ) : (
              <>
                {currentStep !== 1 && <p>{steps[currentStep].text}</p>}

                {currentStep === 1 && selectedChallenges && selectedChallenges.length > 0 && (
                  <div className={classes.selected_challenge_container}>
                    <div className={classes.selected_challenge_title_container}>
                      <div
                        className={`${classes.selected_challenge_title} ${
                          isDarkMode ? classes.dark : classes.light
                        }`}
                      >
                        {t('PAYMENT_STATUS_PAGE.ACCOUNT_TITLE')}{' '}
                        {selectedChallenges[currentChallenge].challengeAmount}$
                        &nbsp;&nbsp;
                        <span
                          style={{ color: isDarkMode ? '#ffdbdb' : '#923434' }}
                        >
                          ({currentChallenge}/{selectedChallenges.length})
                        </span>
                      </div>
                    </div>
                    <div className={classes.selected_challenge_content_container}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('PAYMENT_STATUS_PAGE.OFFERS_DESCRIPTION'),
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('PAYMENT_STATUS_PAGE.STP_OFFER'),
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('PAYMENT_STATUS_PAGE.ECN_OFFER'),
                        }}
                      ></p>
                    </div>
                    <div className={classes.buttons_choice_container}>
                      <MuiButton
                        variant="outlined"
                        size="medium"
                        sx={{
                          borderRadius: '10px',
                          height: '40px',
                          width: '150px',
                          background:
                            currentOfferChoice === 'STP'
                              ? isDarkMode
                                ? '#392a2d'
                                : '#fadadc'
                              : isDarkMode
                              ? '#38393b'
                              : '#cdcdcd',
                          borderColor:
                            currentOfferChoice === 'STP'
                              ? isDarkMode
                                ? '#973838'
                                : '#e74750'
                              : isDarkMode
                              ? '#38393b'
                              : '#cdcdcd',
                        }}
                        onClick={() => setCurrentOfferChoice('STP')}
                      >
                        <img
                          src={isDarkMode ? STP_LIGHT_BUTTON : STP_DARK_BUTTON}
                          width="100"
                        />
                      </MuiButton>

                      <MuiButton
                        variant="outlined"
                        size="medium"
                        sx={{
                          borderRadius: '10px',
                          height: '40px',
                          width: '150px',
                          background:
                            currentOfferChoice === 'ECN'
                              ? isDarkMode
                                ? '#392a2d'
                                : '#fadadc'
                              : isDarkMode
                              ? '#38393b'
                              : '#cdcdcd',
                          borderColor:
                            currentOfferChoice === 'ECN'
                              ? isDarkMode
                                ? '#973838'
                                : '#e74750'
                              : isDarkMode
                              ? '#38393b'
                              : '#cdcdcd',
                        }}
                        onClick={() => setCurrentOfferChoice('ECN')}
                      >
                        <img
                          src={isDarkMode ? ECN_LIGHT_BUTTON : ECN_DARK_BUTTON}
                          width="100"
                          style={{ backgroundSize: 'cover' }}
                        />
                      </MuiButton>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <MuiButton
                        disabled={disableButton}
                        variant="contained"
                        size="medium"
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#e74750',
                          fontWeight: 'bold',
                          width: '115px',
                        }}
                        onClick={() =>
                          handleSetChallengeType({
                            challengeId: selectedChallenges[currentChallenge]
                              .challengeId,
                            type: currentOfferChoice,
                          })
                        }
                      >
                        {t('PAYMENT_STATUS_PAGE.VALIDATE_BUTTON')}
                      </MuiButton>
                    </div>
                  </div>
                )}

                {currentStep === steps.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      (window.location.href = `https://${process.env.REACT_APP_API_URL}/`)
                    }
                  >
                    Go to Challenges
                  </Button>
                )}
              </>
            )}
          </div>
        </Stepper>
      </OldModal>
    </>
  );
};

export default PaymentStatusPage;
