import React, { useEffect } from 'react';

declare global {
  interface Window {
    fbq: any;
  }
}

// 1. Déclarer les props du composant
interface PixelBuySuccessProps {
  purchaseValue: number;
}

// 2. Passer PixelBuySuccessProps à votre composant
const PixelBuySuccess: React.FC<PixelBuySuccessProps> = ({ purchaseValue }) => {
  useEffect(() => {
    const insertMetaPixelScript = () => {
      if (typeof window.fbq === 'undefined') {
        (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = true;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = true;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          'script',
          'https://connect.facebook.net/en_US/fbevents.js',
        );
      }

      if (window.fbq) {
        window.fbq('init', '1262035901654805'); // Nouveau Pixel ID

        // 3. Utiliser la prop "purchaseValue" pour la valeur d'achat
        window.fbq('track', 'Purchase', {
          value: purchaseValue,
          currency: 'EUR',
        });
      }
    };

    insertMetaPixelScript();
  }, [purchaseValue]);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1262035901654805&ev=Purchase&noscript=1"
        alt="Meta Pixel"
      />
    </noscript>
  );
};

export default PixelBuySuccess;
